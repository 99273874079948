import DataInterfaceApi from './BaseApiDataInterface';
// 数据接口
export default class DataInterface extends DataInterfaceApi {
  // 数据接口
  async dataFaceApi(info) {
    return await this.post(`system/DataInterface/dataFaceApi/`, info);
  }
  // 视频监控
  async gclVideoLiveInfo(info) {
    return await this.post(`https://api.m9ch.com/api/video/GclVideoLiveInfo/getListV2`, info);
  }
  // 视频监控 平台的m3u8
  async gclVideoLiveInfoNew() {
    return await this.get(`https://api.m9ch.com/api/video/CtyunDevice/LargeScreenVideo`);
  }
  
  // 视频监控 SNEC 平台的m3u8
  async gclVideoLiveInfoExposition(info) {
    return await this.post(`https://api.m9ch.com/api/video/CtyunDevice/getList`, info);
  }
  
  // 视频监控 SNEC 平台的rtmp
  async gclVideoLiveRtmp(info) {
    return await this.get(`https://api.m9ch.com/api/video/CtyunDevice/selectUrlByCodeAndProto`, info);
  }
  
  // 获取充电港设备信息
  async getFacility(id) {
    return await this.get(`https://api.m9ch.com/api/powerplant/portEquip/getFacility?portId=${id}`);
  }

  // 获取站点监控视频
  async ctyunDeviceList(info) {
    return await this.post(`https://api.m9ch.com/api/video/CtyunDevice/getList`, info);
  }
  /**
   * 获取数据接口
   * @param {*} id
   * @returns
   */
  async fetchDataInterface(id) {
    return await this.get(`system/DataInterface/${id}/Actions/Response?tenantId=gcl`, {})
  }

  // 视频监控 平台的m3u8
  async carouselList(data) {
    return await this.get(`https://api.m9ch.com/api/video/CtyunDevice/getCarouselList`, data);
  }

  async selectUrlByCode( data) {
    return await this.get(`https://api.m9ch.com/api/video/CtyunDevice/selectUrlByCode`, data)
  }
  getcode( data) {
    return  this.get(`https://api.m9ch.com/api/video/CtyunDevice/selectUrlByCode`, data)
  }


}
export const dataInterface = new DataInterface();
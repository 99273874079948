/**
 * 接口地址配置文件
 */
const environment = '';
// const origin = window.location.host + "/";


// let environment = "test";
// origin.includes('dev') && (environment = "dev")
// origin.includes('test') && (environment = "test")
// origin.includes('pre') && (environment = "pre")

export const ADMIN_API = `https://api${environment}.m9ch.com/` //API本地地址
// export const ADMIN_API = `http://screen${environment}.gclme.com/` //API线上地址
// export const ADMIN_API = `http://221.229.215.63:11115/` //PC端地址
// export const ADMIN_API = `http://10.75.1.155:30000/` //PC端地址
